export const QueryKeys = {
  IntegrationSettings: 'integration-settings',
  IntegrationActionItems: 'integration-action-items',
  InstanceSettings: 'instance-settings',
  CustomFields: 'custom-fields',
  CustomField: 'custom-field',
  ConnectSettings: 'connect-settings',
  ConnectVersions: 'connect-versions',
  ConnectSettingsAvailable: 'connect-settings-available',
  DataSource: 'data-source',
  DataSources: 'data-sources',
  Accounts: 'accounts',
  Account: 'account',
  ChangeRequests: 'change-requests',
  Runbooks: 'runbooks',
  RunbooksMeta: 'runbooks-meta',
  RunbookTypes: 'runbook-types',
  RunbookVersions: 'runbook_versions',
  RunbookIntegrations: 'runbook-integrations',
  AiSuggestions: 'ai-suggestions',
  Tasks: 'tasks',
  Teams: 'teams',
  Favorites: 'favorites',
  Users: 'users',
  Snippets: 'snippets',
  SystemParameters: 'system-parameters',
  Runs: 'runs',
  SamlConfigurations: 'saml-configurations',
  UserSettings: 'user-settings',
  MyTasks: 'my-tasks',
  RoleMappings: 'role-mappings',
  RoleTypes: 'role-types',
  RunbookGenerateDescription: 'runbook-generate-description',
  RunbookGenerateSuggestions: 'runbook-generate-suggestions',
  RunbookImprovementPrompts: 'runbook-improvement-prompts'
} as const
