import { useEffect } from 'react'
import { useRecoilCallback, useRecoilStateLoadable, useRecoilValue, useResetRecoilState } from 'recoil'

import {
  runbookCommentsLookup,
  runbookCommentsRequest_INTERNAL,
  taskCommentsLookupState
} from 'main/recoil/runbook/models/runbook/runbook-comments'
import { runbookCommentsPermissions } from 'main/recoil/runbook'
import { RunbookCommentsPermissionsResponse } from 'main/services/api/data-providers/runbook-types'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'
import { CommentModelType } from 'main/data-access/models'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'

/* -------------------------------------------------------------------------- */
/*                                    Get                                     */
/* -------------------------------------------------------------------------- */

export const useGetComment: CommentModelType['useGet'] = (id: number) => {
  const lookup = useGetRunbookCommentsLookup()

  return lookup[id]
}

export const useGetCommentCallback: CommentModelType['useGetCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async (id: number) => {
    const lookup = await snapshot.getPromise(runbookCommentsLookup)
    return lookup?.[id]
  })
}

/* -------------------------------------------------------------------------- */
/*                                  Get All                                   */
/* -------------------------------------------------------------------------- */

// TODO: needs to be converted to single object arg
export const useGetRunbookComments: CommentModelType['useGetAll'] = (opts?: { scope?: 'featured' } | undefined) => {
  useEnsureStableArgs(opts)

  const commentsResponse = useRecoilValue(runbookCommentsRequest_INTERNAL)
  const comments = commentsResponse?.comments ?? []

  if (opts?.scope === 'featured') {
    return comments?.filter(comment => comment.featured) ?? []
  }

  return comments
}

export const useGetRunbookCommentsCallback: CommentModelType['useGetAllCallback'] = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    const commentsResponse = await snapshot.getPromise(runbookCommentsRequest_INTERNAL)
    return commentsResponse?.comments ?? []
  })

/* -------------------------------------------------------------------------- */
/*                                  Get All By                                */
/* -------------------------------------------------------------------------- */

// TODO: needs to be converted to single object arg
export const useGetTaskComments: CommentModelType['useGetAllBy'] = (getAllBy, opts) => {
  useEnsureStableArgs(getAllBy)
  useEnsureStableArgs(opts)

  const taskCommentsLookup = useRecoilValue(taskCommentsLookupState)

  return taskCommentsReturn(taskCommentsLookup, getAllBy.taskInternalId, opts)
}

export const useGetTaskCommentsCallback: CommentModelType['useGetAllByCallback'] = () =>
  useRecoilCallback(({ snapshot }) => async (getAllBy, opts) => {
    const taskCommentsLookup = await snapshot.getPromise(taskCommentsLookupState)

    return taskCommentsReturn(taskCommentsLookup, getAllBy.taskInternalId, opts)
  })

/* -------------------------------------------------------------------------- */
/*                              Get All Loadable                              */
/* -------------------------------------------------------------------------- */

export const useGetLoadableComments: CommentModelType['useGetAllLoadable'] = opts => {
  const [commentsRequestLoadable, setCommentsData] = useRecoilStateLoadable(runbookCommentsRequest_INTERNAL)
  const hasComments = commentsRequestLoadable.state === 'hasValue'

  useEffect(() => {
    if (hasComments) {
      setCommentsData(commentsRequestLoadable.contents)
    }
  }, [hasComments])

  return commentsRequestLoadable.map(response => {
    const comments = response.comments
    return opts?.scope === 'featured' ? comments.filter(comment => comment.featured) : comments
  })
}

export const useGetLoadableCommentsCallback: CommentModelType['useGetAllLoadableCallback'] = () => {
  const [commentsRequestLoadable, setCommentsData] = useRecoilStateLoadable(runbookCommentsRequest_INTERNAL)
  const hasComments = commentsRequestLoadable.state === 'hasValue'

  useEffect(() => {
    if (hasComments) {
      setCommentsData(commentsRequestLoadable.contents)
    }
  }, [hasComments])

  return opts =>
    commentsRequestLoadable.map(response => {
      const comments = response.comments
      return opts?.scope === 'featured' ? comments.filter(comment => comment.featured) : comments
    })
}

export const useGetLoadableCommentsBy: CommentModelType['useGetAllLoadableBy'] = getAllBy => {
  useEnsureStableArgs(getAllBy)

  const loadable = useGetLoadableComments()

  return loadable.map(comments => comments.filter(comment => comment.task_internal_id === getAllBy.taskInternalId))
}

export const useGetLoadableCommentsByCallback: CommentModelType['useGetAllLoadableByCallback'] = () => {
  const loadable = useGetLoadableComments()

  return getAllBy => {
    return loadable.map(comments => comments.filter(comment => comment.task_internal_id === getAllBy.taskInternalId))
  }
}

/* -------------------------------------------------------------------------- */
/*                                    Lookup                                  */
/* -------------------------------------------------------------------------- */

export const useGetRunbookCommentsLookup: CommentModelType['useGetLookup'] = () => {
  return useRecoilValue(runbookCommentsLookup)
}

export const useGetRunbookCommentsLookupCallback: CommentModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(runbookCommentsLookup)
  )

/* -------------------------------------------------------------------------- */
/*                                   Reload                                   */
/* -------------------------------------------------------------------------- */

export const useReloadRunbookComments: CommentModelType['useReload'] = () => {
  const resetComments = useResetRecoilState(runbookCommentsRequest_INTERNAL)

  return async () => {
    resetComments()
  }
}

export const useReloadRunbookCommentsSync: CommentModelType['useReloadSync'] = () =>
  useResetRecoilState(runbookCommentsRequest_INTERNAL)

/* -------------------------------------------------------------------------- */
/*                                     Can                                    */
/* -------------------------------------------------------------------------- */

export const useGetRunbookCommentsPermission: CommentModelType['useCan'] = <
  TKey extends keyof RunbookCommentsPermissionsResponse
>(
  attribute: TKey
) => useRecoilValue(runbookCommentsPermissions({ attribute }))

/* -------------------------------- Internal -------------------------------- */

const taskCommentsReturn = (
  taskCommentsLookup: Record<number, RunbookComment[]>,
  taskInternalId: number,
  scope?: { scope?: 'featured' }
) => {
  const taskComments = taskCommentsLookup[taskInternalId] ?? []

  if (scope?.scope === 'featured') {
    return taskComments?.filter(comment => comment.featured) ?? []
  }

  return taskComments
}
