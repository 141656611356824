import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { format } from 'date-fns'
import { eventManager } from 'event-manager'
import parse from 'html-react-parser'
import { useQueryClient } from 'react-query'
import { useLocation, useMatch, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useIsCentralTeamsMatch } from 'single-spa/route-matchers'

import {
  Box,
  breakpoints,
  Icon,
  IconButton,
  IconName,
  Menu,
  MenuListItem,
  MenuListItemProps,
  resolveColor,
  Tooltip,
  useNotify,
  useRightPanelLayoutOpenState,
  useTheme,
  useToggleFilterPanel,
  windowSizes
} from '@cutover/react-ui'
import { TemplateStatusToggle } from './template-status-toggle'
import { ViewNavigation } from './view-navigation'
import { useActiveSavedView } from '../../layout/sidebar/hooks'
import { CreateSavedViewModal } from '../modals/create-saved-view-modal'
import { ExportRunbooksModal } from '../modals/export-runbooks-modal'
import {
  FilterPanelToggleButton,
  SearchType,
  SubHeader,
  SubHeaderButton,
  SubHeaderPill,
  SubHeaderResultsText,
  SubHeaderSearch
} from 'main/components/layout/shared/sub-header'
import { useSidebarNavContext } from 'main/components/layout/sidebar/nav-context'
import { useBrowserQueryStringToServerQueryString } from 'main/components/shared/filter/filter-params'
import {
  useClearFilterParam,
  useClearFilterParams,
  useFilter,
  useFilters,
  useSetFilterParams
} from 'main/components/shared/filter/filter-provider'
import { useToggleRightPanel } from 'main/components/layout/right-panel'
import {
  useAccount,
  useAccountCustomFields,
  useAccountDashboards
} from 'main/services/api/data-providers/account/account-data'
import { useInitialMount, useLanguage } from 'main/services/hooks'
import { QueryKeys } from 'main/services/queries/query-keys'
import { RunbookTemplateType } from 'main/services/queries/types'
import { useDashboardPdf } from 'main/services/queries/use-dashboard-query'
import { usePermissions } from 'main/services/queries/use-permissions'
import {
  AccountRunbooksResponseMeta,
  RUNBOOK_LIST_DEFAULTS,
  useAccountRunbooksMetaFull
} from 'main/services/queries/use-runbooks-infinite'
import { ConfigModel, CurrentUserModel } from 'main/data-access'

const IGNORE_FILTERS = ['sort_by', 'sort_dir', 'template_type', 'archived', 'group', 'spotlight']

export const WorkspaceSubHeader = memo(
  ({
    runbooksMeta,
    centralTeamsMeta,
    isAnalyticsDashboard
  }: {
    runbooksMeta: any
    centralTeamsMeta: any
    isAnalyticsDashboard: boolean
  }) => {
    const isCentralTeams = useIsCentralTeamsMatch()

    if (isCentralTeams) {
      return <WorkspaceCentralTeamsSubHeader meta={centralTeamsMeta} />
    } else {
      return <WorkspaceRunbooksSubHeader meta={runbooksMeta} isAnalyticsDashboard={isAnalyticsDashboard} />
    }
  }
)

// NOTE: this is not currently used as we're using the angular version of this pace for now.
const WorkspaceCentralTeamsSubHeader = memo(({ meta }: { meta?: any }) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'subHeader' })
  const { filters } = useFilters()
  const searchRef = useRef<SearchType>(null)
  const setFilterParams = useSetFilterParams()
  const clearFilter = useClearFilterParam()
  const initialSearch = useMemo(() => filters.q, [])

  const handleSearch = (input?: string) => {
    if (input) {
      setFilterParams({ q: input })
    } else {
      clearFilter('q')
    }
  }

  useEffect(() => {
    if (!filters?.q) {
      searchRef.current?.clear()
    }
  }, [!filters?.q])

  return (
    <SubHeader.Items>
      <SubHeader.LeftItems>
        <Box flex={false} gap="xsmall" align="center" direction="row">
          <SubHeaderSearch
            a11yTitle={t('searchRunbooks')}
            onSearch={handleSearch}
            initialValue={initialSearch as string}
            ref={searchRef}
          />
        </Box>
        {meta ? <SubHeaderResultsText value={`${meta.total_results} teams`} /> : null}
      </SubHeader.LeftItems>
      <SubHeader.RightItems />
    </SubHeader.Items>
  )
})

const WorkspaceRunbooksSubHeader = memo(
  ({ meta, isAnalyticsDashboard }: { meta?: AccountRunbooksResponseMeta; isAnalyticsDashboard?: boolean }) => {
    const queryClient = useQueryClient()
    const searchRef = useRef<SearchType>(null)
    const { t } = useLanguage('runbooks', { keyPrefix: 'subHeader' })
    const notify = useNotify()
    const { dashboardLookup } = useAccountDashboards()
    const dashboardPdfMutation = useDashboardPdf()
    const isInitialMount = useInitialMount()
    const match = useMatch({ path: '/app/:accountSlug/runbooks/:display', end: false })
    const timelineMatch = useMatch({ path: '/app/:accountSlug/runbooks/timeline' })
    // TODO: combine this code with the code with useParams for the dashboard page
    const dashboardMatch = useMatch({ path: '/app/:accountSlug/runbooks/dashboard/:dashboardId' })
    const display = match?.params.display
    const accountSlug = match?.params.accountSlug
    const { filters } = useFilters()
    const setFilterParams = useSetFilterParams()
    const clearFilters = useClearFilterParams()
    const clearFilter = useClearFilterParam()
    const templateType = useFilter<RunbookTemplateType[]>('template_type')?.[0]
    const group = filters.group as string
    const spotlight = filters.spotlight as string
    const location = useLocation()
    const theme = useTheme()
    const { dashboardId } = useParams<{ dashboardId?: string }>()
    const { account } = useAccount()
    const currentUser = CurrentUserModel.useGet()
    const isPortableRunbooksEnabled = ConfigModel.useIsFeatureEnabled('portable_runbooks')
    const permissions = usePermissions('runbooks')
    const TEMPLATE_TYPE_MAP = {
      default: 'templates',
      snippet: 'snippets',
      off: 'runbooks'
    }
    // TODO: update dashboards query to use object instead of string for query key and use `filters` here instead of
    // adding useBrowserQueryStringToServerQueryString
    const filterParams = useBrowserQueryStringToServerQueryString({
      query: location.search,
      defaults: { dashboardId }
    })
    const { data: fullMeta } = useAccountRunbooksMetaFull(match?.params.accountSlug)
    const toggleFilterPanel = useToggleFilterPanel()
    const toggleSchedulePanel = useToggleRightPanel('runbooks-dashboard-schedule')

    const activeSavedView = useActiveSavedView()
    const initialSearch = useMemo(() => {
      const runbookIdFilter = filters.runbook_id as number[] | undefined
      if (runbookIdFilter) {
        return `#${runbookIdFilter.join(',#')}`
      }
      return filters.q
    }, [])
    const [isSearchOpen, setIsSearchOpen] = useState(!!initialSearch)
    const [showExportRunbooksModal, setShowExportRunbooksModal] = useState(false)

    // clear search input on switching between template type tabs
    useEffect(() => {
      if (isInitialMount) return
      searchRef.current?.clear()
    }, [templateType])

    const [showSaveViewModal, setShowSaveViewModal] = useState(false)
    const [menuIcon, setMenuIcon] = useState<IconName>('more-vertical')

    const appliedFilters = filters && Object.keys(filters ?? {}).filter(key => !IGNORE_FILTERS.includes(key))
    const filterAppliedCount = appliedFilters?.length
    const showSaveViewButton =
      !activeSavedView && (appliedFilters?.length > 0 || (group && timelineMatch) || (spotlight && timelineMatch))

    const handleSavedViewIndicatorClose = () => {
      clearFilters({ newFilters: { template_type: filters?.template_type || [] } })
    }

    const openExportRunbooksModal = () => {
      setShowExportRunbooksModal(true)
    }

    const openImportPortableTempateModal = () => {
      eventManager.emit('open-import-portable-template-modal')
    }

    const canImportRunbooks = permissions('import')
    const allowImport = isPortableRunbooksEnabled && templateType === 'default' && canImportRunbooks

    useEffect(() => {
      if (!filters?.q && !filters?.runbook_id) {
        searchRef.current?.clear()
      }
    }, [!filters?.q, !filters?.runbook_id])

    const handleSearch = (input?: string) => {
      const trimmedInput = input?.trim()
      const inputAfterHash = trimmedInput?.substring(trimmedInput.indexOf('#'))
      const isRunbookIdSearch = inputAfterHash?.startsWith('#')

      // Allow users search for runbooks with # in the name without it being treated as a runbook id
      const stringWithoutHashOrComma = trimmedInput?.replace(/[#, ]/g, '')
      const isOnlyNumbers = /^\d+$/.test(stringWithoutHashOrComma || '')

      if (input && isRunbookIdSearch && isOnlyNumbers) {
        const runbookIds = input.replace(/#|\s/g, '').split(',')
        setFilterParams({ runbook_id: runbookIds, q: undefined })
      } else if (input) {
        setFilterParams({ q: input, runbook_id: undefined })
      } else {
        const { q: _q, runbook_id: _runbook_id, ...rest } = filters
        clearFilters({ newFilters: rest })
      }
    }

    const downloadPDF = () => {
      const dashboardId = dashboardMatch?.params.dashboardId
      const accountSlug = dashboardMatch?.params.accountSlug
      const dashboard = dashboardId && dashboardLookup && dashboardLookup[parseInt(dashboardId)]
      const date = format(new Date(), "dd'-'MM'-'yyyy'_at_'HH'-'mm")
      const fileName = dashboard ? `${dashboard?.name.replace(/ /g, '_')}_${date}.pdf` : null

      setMenuIcon('spinner')

      if (fileName && dashboardId && accountSlug) {
        dashboardPdfMutation.mutate(
          { file_name: fileName, id: dashboardId, account_id: accountSlug, template_type: ['off'], ...filters },
          {
            onSuccess: () => {
              setMenuIcon('check')
              notify.success(t('downloadPdf.notification.success.message'), {
                title: t('downloadPdf.notification.success.title')
              })
            },
            onSettled: () => {
              setTimeout(() => {
                setMenuIcon('more-vertical')
              }, 1500)
            }
          }
        )
      }
    }

    const menuOptions: MenuListItemProps[] = useMemo(() => {
      const options: MenuListItemProps[] = [
        {
          label: t('dropdownMenuItem.export'),
          icon: 'download',
          onClick: openExportRunbooksModal
        },
        {
          label: !!filters?.archived ? t('dropdownMenuItem.showUnarchived') : t('dropdownMenuItem.showArchived'),
          icon: 'archive',
          onClick: !!filters?.archived ? () => clearFilter('archived') : () => setFilterParams({ archived: 1 })
        },
        ...(display !== 'timeline'
          ? [
              {
                label: t('dropdownMenuItem.reload'),
                icon: 'refresh' as const,
                onClick: () => {
                  if (dashboardMatch) {
                    queryClient.refetchQueries({
                      queryKey: ['dashboards', dashboardId, accountSlug, filterParams]
                    })
                  } else {
                    queryClient.resetQueries([
                      accountSlug,
                      QueryKeys.Runbooks,
                      { ...RUNBOOK_LIST_DEFAULTS, ...filters }
                    ])
                  }
                }
              }
            ]
          : []),
        ...(allowImport
          ? [
              {
                label: t('dropdownMenuItem.addTemplate'),
                icon: 'add' as const,
                onClick: openImportPortableTempateModal
              }
            ]
          : [])
      ]

      if (dashboardMatch && !isAnalyticsDashboard) {
        options.splice(2, 0, {
          label: t('dropdownMenuItem.downloadPdf'),
          icon: 'download',
          onClick: downloadPDF
        })
      }

      return options
    }, [filters, display, t, setFilterParams, queryClient, dashboardMatch, dashboardId, accountSlug])

    const isRightPanelOpen = useRightPanelLayoutOpenState()
    const { isLeftSidebarOpen } = useSidebarNavContext()

    const leftItemsFirstItem = (
      <Box
        flex={false}
        gap="xsmall"
        align="center"
        direction="row"
        width={{ max: !activeSavedView ? '260px' : undefined }}
      >
        <Box width={{ min: '40px' }}>
          <FilterPanelToggleButton
            filterCount={filterAppliedCount && filterAppliedCount > 0 ? filterAppliedCount : undefined}
            onClick={() => toggleFilterPanel()}
            onKeyUp={() => toggleFilterPanel()}
            tip={t('filterRunbooks')}
          />
        </Box>
        {!activeSavedView && (
          <SubHeaderSearch
            ref={searchRef}
            tip={t('searchRunbooks')}
            onSearch={handleSearch}
            initialValue={initialSearch as string}
            placeholder={t('searchRunbooksPlaceholder', { itemType: TEMPLATE_TYPE_MAP[templateType ?? 'off'] })}
            setIsSearchOpen={setIsSearchOpen}
          />
        )}
      </Box>
    )

    // Note, temporarily hiding analytics dash features until time to test
    return (
      <SubHeader.Items>
        <SubHeader.LeftItems>
          {!isAnalyticsDashboard && leftItemsFirstItem}
          {!activeSavedView && (templateType === 'snippet' || templateType === 'default') && <TemplateStatusToggle />}
          {activeSavedView && <SubHeaderPill label={activeSavedView.name} onClose={handleSavedViewIndicatorClose} />}
          {group && timelineMatch && (
            <GroupSpotlightPill type="group" value={group} onClose={() => clearFilter('group')} />
          )}
          {spotlight && timelineMatch && (
            <GroupSpotlightPill type="spotlight" value={spotlight} onClose={() => clearFilter('spotlight')} />
          )}
          {showSaveViewButton && (
            <SubHeaderButton data-testid="save-view" onClick={() => setShowSaveViewModal(true)} label={t('saveView')} />
          )}

          {meta && fullMeta && (
            <SubHeaderResultsText
              value={
                filters?.archived
                  ? t('archivedResults', { amount: `${meta.filtered_results_count}/${meta.total_results_count}` })
                  : t('results', { amount: `${meta.filtered_results_count}/${meta.total_results_count}` })
              }
            />
          )}
        </SubHeader.LeftItems>
        <SubHeader.RightItems>
          {display && display !== 'dashboard' && (
            <ViewNavigationWrapper
              isRightPanelOpen={isRightPanelOpen}
              isLeftSidebarOpen={isLeftSidebarOpen}
              isSearchOpen={isSearchOpen}
            >
              <ViewNavigation templateType={templateType} />
            </ViewNavigationWrapper>
          )}
          {dashboardMatch && !isAnalyticsDashboard && (
            <Tooltip content={t('schedule')} placement="top">
              <Box
                height="40px"
                width="40px"
                background="bg-1"
                justify="center"
                align="center"
                round="full"
                onClick={() => {
                  toggleSchedulePanel({
                    accountId: account?.id,
                    dashboardId: dashboardId ? parseInt(dashboardId, 10) : undefined,
                    currentUserId: currentUser ? currentUser.id : undefined
                  })
                }}
                css={`
                  &:hover {
                    background-color: ${resolveColor('bg-2', theme)};
                  }
                  &:active {
                    background-color: ${resolveColor('bg-3', theme)};
                  }
                `}
              >
                <Icon icon="share" color="text-light" />
              </Box>
            </Tooltip>
          )}
          <Menu
            trigger={
              <IconButton
                tertiary
                data-testid="sub-header-options-menu"
                label={t('moreOptions')}
                disableTooltip
                icon={menuIcon}
              />
            }
          >
            {menuOptions.map(item => (
              <MenuListItem label={item.label} key={item.label} icon={item.icon} onClick={item.onClick} />
            ))}
          </Menu>
        </SubHeader.RightItems>
        {account && showSaveViewModal && (
          <CreateSavedViewModal account={account} open={showSaveViewModal} setOpen={setShowSaveViewModal} />
        )}
        <ExportRunbooksModal
          open={showExportRunbooksModal}
          setOpen={setShowExportRunbooksModal}
          accountSlug={accountSlug}
          templateType={templateType ?? 'off'}
        />
      </SubHeader.Items>
    )
  }
)

const GroupSpotlightPill = ({
  type,
  value,
  onClose
}: {
  type: 'group' | 'spotlight'
  value: string | number
  onClose: () => void
}) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'subHeader' })
  const { customFieldsLookup } = useAccountCustomFields()

  const title = typeof value === 'number' ? customFieldsLookup?.[value].name : value
  const text =
    title && `${type === 'group' ? t('groupBy') : t('spotlight')}: ${parse(title[0].toUpperCase() + title.slice(1))}`

  return text ? (
    <SubHeaderPill
      label={text}
      onClose={onClose}
      background="bg-2"
      color="text-light"
      hasTooltip
      width={{ max: '200px' }}
    />
  ) : null
}

const ViewNavigationWrapper = styled(Box)<{
  isLeftSidebarOpen: boolean
  isRightPanelOpen: boolean
  isSearchOpen: boolean
}>`
  @media ${breakpoints.md}, ${breakpoints.sm} {
    display: none;
  }

  @media screen and (min-width: ${windowSizes.md}px) and (max-width: 1068px) {
    display: ${({ isLeftSidebarOpen, isRightPanelOpen }) => isLeftSidebarOpen && isRightPanelOpen && 'none'};
  }

  @media screen and (min-width: ${windowSizes.md}px) and (max-width: 940px) {
    display: ${({ isLeftSidebarOpen, isSearchOpen }) => isLeftSidebarOpen && isSearchOpen && 'none'};
  }

  @media screen and (min-width: ${windowSizes.md}px) and (max-width: 1230px) {
    display: ${({ isLeftSidebarOpen, isRightPanelOpen, isSearchOpen }) =>
      isLeftSidebarOpen && isRightPanelOpen && isSearchOpen && 'none'};
  }
`
