import { useMutation, useQuery, UseQueryOptions } from 'react-query'

import { Nullish } from '@cutover/utility-types'
import { apiClient, ApiError } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'
import { RunbookTypesSchema } from './schemas/runbook-types-schema'
import { ApprovalFlowType, RunbookTypeType } from 'main/services/queries/types'
import { RunbookTypeFilterType } from 'main/services/settings/filtering'
import { serverQueryObjectToServerQueryString } from 'main/components/shared/filter/filter-params'
import { useSetPermissions } from 'main/services/queries/use-permissions'

/* -------------------------------------------------------------------------- */
/*                                API - Queries                               */
/* -------------------------------------------------------------------------- */

/* ----------------------------------- show --------------------------------- */
export type GetRunbookTypeMetaPermissions = {
  update: [number] | []
  destroy: [number] | []
  global: [number] | []
}

export type GetRunbookTypeMeta = {
  permissions: GetRunbookTypeMetaPermissions
  available_approval_flows: ApprovalFlowType[]
}

export type GetRunbookTypeResponse = {
  meta: GetRunbookTypeMeta
  runbook_type: RunbookTypeType
}

export function useRunbookTypeQuery(id: number, options: UseQueryOptions<GetRunbookTypeResponse, ApiError> = {}) {
  const setPermissions = useSetPermissions('runbook-type')
  return useQuery<GetRunbookTypeResponse, ApiError>(
    [QueryKeys.RunbookTypes, String(id)],
    async () => {
      const { data } = await apiClient.get<GetRunbookTypeResponse>({
        url: `runbook_types/${id}`
      })
      setPermissions(data.meta.permissions)
      return data
    },
    options
  )
}

/* ---------------------------------- index --------------------------------- */

export type GetRunbookTypesMetaPermissions = {
  create: [number] | []
  global: [number] | []
}

export type GetRunbookTypesMeta = {
  permissions: GetRunbookTypesMetaPermissions
  permitted_accounts: number[]
  total_results: number
  filtered_results: number
  available_approval_flows: ApprovalFlowType[]
}

export type GetRunbookTypesResponse = {
  meta: GetRunbookTypesMeta
  runbook_types: RunbookTypeType[]
}

export const useRunbookTypesQuery = (
  params: RunbookTypeFilterType = {},
  options: UseQueryOptions<GetRunbookTypesResponse, ApiError> = {}
) => {
  const serverQueryParams = serverQueryObjectToServerQueryString({ queryObject: params })
  const setPermissions = useSetPermissions('runbook-types')

  return useQuery<GetRunbookTypesResponse, ApiError>(
    [QueryKeys.RunbookTypes, serverQueryParams],
    async () => {
      const { data } = await apiClient.get<GetRunbookTypesResponse>({ url: `runbook_types${serverQueryParams}` })
      setPermissions(data.meta.permissions)
      return data
    },
    options
  )
}

/* -------------------------------------------------------------------------- */
/*                                API Mutations                               */
/* -------------------------------------------------------------------------- */

/* --------------------------------- update --------------------------------- */

export type UpdateRunbookTypePayload = RunbookTypesSchema & { id: number }

export type UpdateRunbookTypesMetaPermissions = {
  update: [number] | []
  destroy: [number] | []
  global: [number] | []
}

export type UpdateRunbookTypeMeta = {
  permissions: UpdateRunbookTypesMetaPermissions
  available_approval_flows: ApprovalFlowType[]
}

export type UpdateRunbookTypeResponse = {
  meta: UpdateRunbookTypeMeta
  runbook_type: RunbookTypeType
}

export const useUpdateRunbookTypeMutation = () => {
  return useMutation<UpdateRunbookTypeResponse, ApiError, UpdateRunbookTypePayload>(
    [QueryKeys.RunbookTypes],
    async payload => {
      const { data } = await apiClient.patch({
        url: `runbook_types/${payload.id}`,
        data: payload
      })

      return data as UpdateRunbookTypeResponse
    }
  )
}

/* --------------------------------- create --------------------------------- */

export type CreateRunbookTypesMetaPermissions = {
  update: [number] | []
  destroy: [number] | []
  global: [number] | []
}
export type CreateRunbookTypePayload = RunbookTypesSchema

export type CreateRunbookTypeMeta = {
  permissions: CreateRunbookTypesMetaPermissions
  available_approval_flows: ApprovalFlowType[]
}

export type CreateRunbookTypeResponse = {
  meta: CreateRunbookTypeMeta
  runbook_type: RunbookTypeType
}

export const useRunbookTypeCreateMutation = () => {
  return useMutation<CreateRunbookTypeResponse, ApiError, CreateRunbookTypePayload>(
    [QueryKeys.RunbookTypes],
    async payload => {
      const { data } = await apiClient.post<CreateRunbookTypePayload, CreateRunbookTypeResponse>({
        url: 'runbook_types',
        data: payload
      })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return data!
    }
  )
}

// --------------------------------------------------------------------------------------------------

// TODO: review other JSON reponse
export type RunbookType = {
  id: number
  name: string
  description: string
  visibility: 'global' | 'account'
  accountId: Nullish<number>
  dynamic: boolean
  incident: boolean
  disabled: boolean
  iconName: string
  iconColor: string
  ai_create_enabled?: boolean
  // accountName: string
  // archived: boolean
  // default: boolean
}
