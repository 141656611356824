import { ReactNode } from 'react'
import { useRecoilCallback } from 'recoil'
import styled from 'styled-components'

import { Avatar, TaskTypeIcon } from '@cutover/react-ui'
import { streamsPermittedState, teamsStateLookup, usersLookupState } from 'main/recoil/runbook'
import { TaskType } from 'main/services/queries/types'
import { taskEditTaskTypesState } from 'main/recoil/runbook/models/tasks/task-edit'
import { MenuType } from './data-access-hooks__TEMPORARY'

/* ------------------------- Task create input menus ------------------------ */

export type TaskShortcutMenuItem = {
  id: number
  label: string
  icon?: ReactNode | string
  props?: any
}

export const useBuildTaskTypeMenuItems = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const { taskTypes, taskTypeIntegrations } = await snapshot.getPromise(taskEditTaskTypesState)
    const taskTypesOptions =
      taskTypes?.map((t: TaskType) => ({
        id: t.id,
        label: t.name,
        icon: <TaskTypeIcon icon={t.icon} />,
        linkable: t.linkable
      })) ?? []
    const taskTypesIntegrationsOptions =
      taskTypeIntegrations
        ?.filter((t: TaskType) => {
          return (
            !t.name.includes('Integrations::Apps::MountPoint') &&
            t.integration_action_items[0].on.includes('PubSub::Task::Started')
          )
        })
        .map((t: TaskType) => ({
          id: t.id,
          label: t.integration_action_items[0].name,
          icon: (
            <IntegrationIcon
              alt={`${t.integration_action_items[0].name}-icon`}
              src={
                t.integration_action_items[0].image_url || t.integration_action_items[0].integration_setting.image_url
              }
            />
          )
        })) ?? []

    return [...taskTypesOptions, ...taskTypesIntegrationsOptions]
  })
}

const IntegrationIcon = styled.img`
  height: 22px;
  width: 22px;
`

export const useBuildUsersAndTeamsMenuItems = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const teamsLookup = await snapshot.getPromise(teamsStateLookup)
    const usersLookup = await snapshot.getPromise(usersLookupState)

    const teams = Object.values(teamsLookup).map(team => {
      const { id, name, linked, color } = team
      const avatarSubject = { id, name, linked, color }
      return {
        id,
        label: name,
        icon: <Avatar subject={avatarSubject} size="small" />,
        props: { team, avatarSubject }
      }
    })

    const users = Object.values(usersLookup).map(user => {
      const { id, first_name: firstName, last_name: lastName, name, online, color } = user
      const avatarSubject = { id, first_name: firstName, last_name: lastName, name, online, color }
      return {
        id,
        label: name,
        icon: <Avatar subject={avatarSubject} size="small" />,
        props: { avatarSubject }
      }
    })

    return [...teams, ...users]
  })
}

export const useBuildStreamsMenuItems = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const streamsLookup = await snapshot.getPromise(streamsPermittedState)

    const streams = streamsLookup.map(stream => {
      const { id, name } = stream
      return {
        id,
        label: name,
        props: { stream }
      }
    })

    return streams
  })
}

export const useMatchTaskTypesMenu = (menuOpenCallback: (items: TaskShortcutMenuItem[], type: MenuType) => void) => {
  const buildTaskTypeMenuItems = useBuildTaskTypeMenuItems()
  return useRecoilCallback(() => async () => {
    const taskTypes = await buildTaskTypeMenuItems()
    menuOpenCallback(taskTypes, 'taskTypes')
  })
}

export const useMatchUsersAndTeamsMenu = (
  menuOpenCallback: (items: TaskShortcutMenuItem[], type: MenuType) => void,
  selectedTeams: number[],
  selectedUsers: number[]
) => {
  const buildUsersAndTeamsMenuItems = useBuildUsersAndTeamsMenuItems()
  return useRecoilCallback(() => async () => {
    const usersAndTeams = await buildUsersAndTeamsMenuItems()
    const filterSelectedUsersAndTeams = usersAndTeams?.filter((item: TaskShortcutMenuItem) => {
      return item.props?.team?.team_id ? !selectedTeams.includes(item.id) : !selectedUsers.includes(item.id)
    })
    menuOpenCallback(filterSelectedUsersAndTeams, 'usersAndTeams')
  })
}

export const useMatchStreamsMenu = (menuOpenCallback: (items: TaskShortcutMenuItem[], type: MenuType) => void) => {
  const buildStreamsMenuItems = useBuildStreamsMenuItems()
  return useRecoilCallback(() => async () => {
    const streams = await buildStreamsMenuItems()
    menuOpenCallback(streams, 'streams')
  })
}
