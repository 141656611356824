import { memo } from 'react'

import { FilterAccordion, FilterAccordionPanel } from '@cutover/react-ui'
import { ExtendedCustomField } from 'main/recoil/runbook/models/account/custom-fields'
import { CustomFieldMultiSelectControl } from 'main/recoil/shared/filters/controls/custom-field-multi-select-control'
import { FilterPanel } from 'main/components/layout/filter-panel'
import {
  CompletionGroup,
  DateGroup,
  GroupsHeader,
  LevelGroup,
  OtherGroup,
  PermissionsGroup,
  QuickFiltersGroup,
  SnippetsGroup,
  StageGroup,
  StreamGroup,
  TaskTypeGroup,
  TeamGroup,
  UserGroup
} from './groups'
import { CustomFieldDateSelectControl, CustomFieldTextSearchControl } from 'main/recoil/shared/filters/controls'
import { SavedFiltersGroup } from './groups/saved-filters-group'
import { isSearchableCustomField } from 'main/services/tasks/filtering'
import { IDS as LAYOUT_IDS } from 'main/components/layout/layout-constants'
import {
  useCustomFieldAppliedFiltersState,
  useHasSnippets,
  useRunbookFilterCustomFieldsState
} from 'main/recoil/data-access'
import { SavedFilterModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'

export const RunbookFilter = () => {
  const hasSavedFilters = SavedFilterModel.useGetAll().length > 0
  const hasSnippets = useHasSnippets()

  return (
    <FilterPanel>
      <RunbookFilterInner hasSavedFilters={hasSavedFilters} hasSnippets={hasSnippets} />
    </FilterPanel>
  )
}

export const RunbookFilterInner = memo(
  ({ hasSavedFilters, hasSnippets }: { hasSavedFilters: boolean; hasSnippets: boolean }) => {
    const customFields = useRunbookFilterCustomFieldsState()
    const customFieldFilters = useCustomFieldAppliedFiltersState()
    const { t } = useLanguage('runbook', { keyPrefix: 'filters' })

    return (
      <FilterAccordion
        a11yTitle={t('panelAriaLabel')}
        renderMode="active"
        isLayoutFilter
        scrollContainer={LAYOUT_IDS.FILTER_PANEL}
      >
        <GroupsHeader key="header" />
        {hasSavedFilters && <SavedFiltersGroup key="saved-filters" />}
        {hasSnippets && <SnippetsGroup key="snippets" />}
        <StreamGroup key="streams" />
        <QuickFiltersGroup key="quick-filters" />
        <TeamGroup key="team" />
        <UserGroup key="user" />
        <TaskTypeGroup key="task-type" />
        <LevelGroup key="level" />
        <StageGroup key="stage" />
        <CompletionGroup key="completion" />
        <PermissionsGroup key="permissions" />
        <DateGroup key="date" />
        <OtherGroup key="other" />
        {customFields.map(customField => {
          return (
            <CustomFieldGroup
              customField={customField}
              applied={customFieldFilters && customField.id in customFieldFilters}
              key={customField.id}
            />
          )
        })}
      </FilterAccordion>
    )
  }
)

const CustomFieldGroup = memo(({ customField, applied }: { customField: ExtendedCustomField; applied?: boolean }) => {
  return (
    <FilterAccordionPanel applied={applied} label={customField.display_name ?? customField.name} key={customField.id}>
      {customField.field_type.slug === 'datetime' ? (
        <CustomFieldDateSelectControl id={customField.id} />
      ) : customField.field_type.select_type !== 'none' || isSearchableCustomField(customField.type) ? (
        <CustomFieldMultiSelectControl id={customField.id} />
      ) : customField.field_type.slug === 'text' || customField.field_type.slug === 'textarea' ? (
        <CustomFieldTextSearchControl id={customField.id} />
      ) : null}
    </FilterAccordionPanel>
  )
})
