import { useCallback } from 'react'
import { useRecoilCallback, useRecoilTransaction_UNSTABLE, useRecoilValue } from 'recoil'
import { produce } from 'immer'
import { keyBy } from 'lodash'

import {
  runbookVersionResponseState_INTERNAL,
  taskListTaskState,
  teamsState,
  teamsStateLookup,
  teamState,
  usersTeamsPermissions,
  userTeamIdsRecordState
} from 'main/recoil/runbook'
import { GetRunbookVersionResponse, RunbookTeamsPermissionsResponse } from 'main/services/queries/use-runbook-versions'
import { RunbookTeamModelType } from 'main/data-access/models'
import { useGetTask } from './task'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'
import { updateAllChangedTasks } from './shared-updates'
import {
  RunbookResponse,
  RunbookTeamDestroyResponse,
  RunbookTeamUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import { RunbookTeam, User } from 'main/services/queries/types'

export const useTeamValue: RunbookTeamModelType['useGet'] = id => useRecoilValue(teamState(id))

export const useTeamValueCallback: RunbookTeamModelType['useGetCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (id: number) =>
        await snapshot.getPromise(teamState(id))
  )

export const useAllTeamsValue: RunbookTeamModelType['useGetAll'] = () => {
  return useRecoilValue(teamsState)
}

export const useAllTeamsValueCallback: RunbookTeamModelType['useGetAllCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(teamsState)
  )

export const useGetAllTeamsBy: RunbookTeamModelType['useGetAllBy'] = getAllBy => {
  /* eslint-disable react-hooks/rules-of-hooks */
  useEnsureStableArgs(getAllBy)

  if (getAllBy.taskId) {
    const teamLookup = useRecoilValue(teamsStateLookup)
    const { runbook_team_ids } = useGetTask(getAllBy.taskId)
    return getAllTeamsByIds({ teamLookup, runbookTeamIds: runbook_team_ids })
  }

  const userIdToTeamIdsLookup = useRecoilValue(userTeamIdsRecordState)
  const teamLookup = useRunbookTeamsLookup()
  return getAllTeamsByUserId({ userIdToTeamIdsLookup, teamLookup, id: getAllBy.userId as number })
  /* eslint-enable react-hooks/rules-of-hooks */
}

export const useGetAllTeamsByCallback: RunbookTeamModelType['useGetAllByCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async getAllBy => {
    if (getAllBy.taskId) {
      const teamLookup = await snapshot.getPromise(teamsStateLookup)
      const teamIds = (await snapshot.getPromise(taskListTaskState(getAllBy.taskId))).runbook_team_ids
      return getAllTeamsByIds({ teamLookup, runbookTeamIds: teamIds })
    }

    const userIdToTeamIdsLookup = await snapshot.getPromise(userTeamIdsRecordState)
    const teamLookup = await snapshot.getPromise(teamsStateLookup)
    return getAllTeamsByUserId({ userIdToTeamIdsLookup, teamLookup, id: getAllBy.userId as number })
  })
}

export const useRunbookTeamsLookup: RunbookTeamModelType['useGetLookup'] = () => useRecoilValue(teamsStateLookup)

export const useRunbookTeamsLookupCallback: RunbookTeamModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(teamsStateLookup)
  )

export const useCanRunbookteam: RunbookTeamModelType['useCan'] = permission => {
  useEnsureStableArgs(permission)

  return useRunbookTeamsPermission({ attribute: permission })
}

/* -------------------------------------------------------------------------- */
/*                                   Actions                                  */
/* -------------------------------------------------------------------------- */

export const useOnRunbookTeamAction = () => {
  const processRunbookTeamUpdateResponse = useProcessRunbookTeamUpdateResponse()
  const processRunbookTeamDeleteResponse = useProcessRunbookTeamDestroyResponse()

  return useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'update':
          processRunbookTeamUpdateResponse(response as RunbookTeamUpdateResponse)
          break
        case 'destroy':
          processRunbookTeamDeleteResponse(response as RunbookTeamDestroyResponse)
        default:
          return
      }
    },
    [processRunbookTeamUpdateResponse, processRunbookTeamDeleteResponse]
  )
}

/* -------------------------------- Internal -------------------------------- */

const useRunbookTeamsPermission = <TKey extends keyof RunbookTeamsPermissionsResponse>({
  attribute
}: {
  attribute: TKey
}) => {
  return useRecoilValue(usersTeamsPermissions({ attribute }))
}

const getAllTeamsByIds = ({
  teamLookup,
  runbookTeamIds
}: {
  teamLookup: Record<number, RunbookTeam>
  runbookTeamIds: number[]
}) => {
  return runbookTeamIds?.map(id => teamLookup[id])
}

const getAllTeamsByUserId = ({
  userIdToTeamIdsLookup,
  teamLookup,
  id
}: {
  teamLookup: Record<number, RunbookTeam>
  userIdToTeamIdsLookup: Record<number, number[]>
  id: number
}) => {
  return userIdToTeamIdsLookup[id]?.map(id => teamLookup[id]) || []
}

export const useProcessRunbookTeamUpdateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookTeamUpdateResponse) => {
    const { set } = transactionInterface

    updateAllChangedTasks(transactionInterface)(response.meta.changed_tasks)

    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        const runbookUserLookup = keyBy(prevRunbookVersionResponse.meta.users, 'id')

        const { role_types } = response.runbook_team

        if (role_types && role_types?.[0]?.users.length > 0) {
          role_types[0].users?.forEach(user => {
            if (!runbookUserLookup[user.id]) {
              draftRunbookVersionResponse.meta.users.push(user)
            }
          })
        }

        const { role_types: roleTypes, name } = response.runbook_team
        const userIds = roleTypes?.[0]?.users.map((user: User) => user.id)
        const existingRunbookTeam = draftRunbookVersionResponse.meta.runbook_teams.find(
          team => team.id === response.runbook_team.id
        )

        if (existingRunbookTeam) {
          existingRunbookTeam.user_ids = userIds
          existingRunbookTeam.name = name
        }

        const { users_removed } = response.meta

        removeUsersFromMeta({ userIds: users_removed, draft: draftRunbookVersionResponse })
      })
    )
  })
}

const useProcessRunbookTeamDestroyResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookTeamDestroyResponse) => {
    const { set } = transactionInterface

    updateAllChangedTasks(transactionInterface)(response.meta.changed_tasks)

    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        const { removed_user_ids } = response.meta

        removeUsersFromMeta({ userIds: removed_user_ids, draft: draftRunbookVersionResponse })

        const runbookTeamIndex = draftRunbookVersionResponse.meta.runbook_teams.findIndex(
          team => team.id === response.runbook_team.id
        )
        draftRunbookVersionResponse.meta.runbook_teams.splice(runbookTeamIndex, 1)
      })
    )
  })
}

const removeUsersFromMeta = ({ userIds, draft }: { userIds: number[]; draft: GetRunbookVersionResponse }) => {
  if (userIds?.length > 0) {
    userIds.forEach(id => {
      const index = draft.meta.users.findIndex(user => user.id === id)
      draft.meta.users.splice(index, 1)
    })
  }
}
