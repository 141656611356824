import { useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import {
  Box,
  IconButton,
  Menu,
  MenuListItem,
  MenuListItemProps,
  useNotify,
  useToggleFilterPanel
} from '@cutover/react-ui'
import {
  FilterPanelToggleButton,
  SearchType,
  SubHeaderButton,
  SubHeaderResultsText,
  SubHeaderSearch
} from 'main/components/layout/shared/sub-header'
import { ActiveRunModel, RunbookViewModel, TaskModel } from 'main/data-access'
import {
  useActiveTasksFilter,
  useClearFilterState,
  useFilterCount,
  useHasFiltersState,
  useMyTasksFilter,
  useSearchQueryFilter
} from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { SaveFilterModal } from '../modals/save-filter-modal'
import { ViewNavigation } from './view-navigation'

export const RunbookSubHeaderDefaultLeft = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })
  const searchRef = useRef<SearchType>(null)

  const toggleFilterPanel = useToggleFilterPanel()
  const numFilters = useFilterCount()
  const numFilteredTasks = TaskModel.useGetCount({ scope: 'filtered' })
  const numAllTasks = TaskModel.useGetCount()
  const [showSaveViewModal, setShowSaveViewModal] = useState(false)
  const [searchFilter, setSearchFilter] = useSearchQueryFilter()
  const [isFilteringMyTasks, setIsFilteringMyTasks] = useMyTasksFilter()
  const [isFilteringActiveTasks, setIsFilteringActiveTasks] = useActiveTasksFilter()

  const clearFilteringMyTasks = useClearFilterState('mt')
  const clearFilteringActiveTasks = useClearFilterState('at')
  const clearSearchQuery = useClearFilterState('q')
  const showSaveViewButton = useHasFiltersState()
  const run = ActiveRunModel.useGet()

  const handleSearch = (input?: string) => {
    if (input) {
      setSearchFilter(input)
    }
  }

  const handleToggleMyTasks = () => {
    if (isFilteringMyTasks) {
      clearFilteringMyTasks()
    } else {
      setIsFilteringMyTasks(true)
    }
  }

  const handleToggleActiveTasks = () => {
    if (isFilteringActiveTasks) {
      clearFilteringActiveTasks()
    } else {
      setIsFilteringActiveTasks(true)
    }
  }

  const handleClose = () => {
    if (searchFilter) {
      clearSearchQuery()
    }
  }

  useEffect(() => {
    if (searchFilter === undefined) {
      searchRef.current?.clear()
    }
  }, [searchFilter])

  return (
    <>
      <>
        <Box width={{ min: '40px' }}>
          <FilterPanelToggleButton
            filterCount={numFilters}
            onClick={() => toggleFilterPanel()}
            onKeyUp={() => toggleFilterPanel()}
            tip={t('filterTasks')}
          />
        </Box>
        <SubHeaderSearch ref={searchRef} onSearch={handleSearch} onClose={handleClose} initialValue={searchFilter} />
        <SubHeaderButton onClick={() => handleToggleMyTasks()} active={!!isFilteringMyTasks} label={t('myTasks')} />
        {run?.mode === 'active' && (
          <SubHeaderButton
            onClick={() => handleToggleActiveTasks()}
            active={!!isFilteringActiveTasks}
            label={t('activeTasks')}
          />
        )}
        {showSaveViewButton && (
          <SubHeaderButton
            data-testid="save-filter"
            onClick={() => setShowSaveViewModal(true)}
            label={t('saveFilter')}
          />
        )}
      </>
      {numAllTasks !== undefined && numFilteredTasks !== undefined ? (
        <Box justify="center" margin={{ left: '4px' }}>
          <SubHeaderResultsText value={t('results', { amount: `${numFilteredTasks}/${numAllTasks}` })} />
        </Box>
      ) : null}
      {showSaveViewModal && <SaveFilterModal closeModal={() => setShowSaveViewModal(false)} />}
    </>
  )
}

export const RunbookSubHeaderDefaultRight = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })
  const notify = useNotify()

  const isHighlightMode = RunbookViewModel.useGet('highlightMode')
  const { can: canEditRunbook } = RunbookViewModel.usePermission('edit:runbook')
  const copyIds = RunbookViewModel.useGet('copyIds')
  const setHighlightMode = RunbookViewModel.useAction('highlightMode:toggle')
  const openModal = RunbookViewModel.useAction('modal:open')
  const getEditPermission = RunbookViewModel.usePermissionCallback('edit:runbook')
  const getCopyIds = RunbookViewModel.useGetCallback('copyIds')
  const getIsHighlightMode = RunbookViewModel.useGetCallback('highlightMode')

  useHotkeys('meta+h', async () => setHighlightMode(!(await getIsHighlightMode())), { preventDefault: true }, [])
  useHotkeys(
    'meta+v',
    async () => {
      const editPermission = await getEditPermission()
      const copiedIds = await getCopyIds()

      if (copiedIds.length === 0) return notify.warning(t('noTasksSelected'), { title: t('noTasksSelectedTitle') })

      if (editPermission.can) {
        openModal({ type: 'tasks-paste' })
      } else {
        notify.warning(t('unableToPaste'), { title: t('unableToPasteTitle') })
      }
    },
    { preventDefault: true },
    []
  )

  return (
    <>
      <ViewNavigation />
      {canEditRunbook && copyIds.length > 0 && (
        <IconButton
          tertiary
          label={t('pasteSelected')}
          badge={{ type: 'primary', label: copyIds.length, 'data-testid': 'runbook-paste-badge' }}
          icon="paste"
          tipPlacement="top"
          onClick={() => openModal({ type: 'tasks-paste' })}
        />
      )}
      <RunbookSubHeaderDefaultMenu />
      {isHighlightMode && (
        <IconButton
          icon="highlight-mode"
          label={t('filterMode.highlightLabel')}
          tipPlacement="top"
          onClick={() => setHighlightMode(false)}
        />
      )}
    </>
  )
}

export const RunbookSubHeaderDefaultMenu = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })
  const removeCopyIds = RunbookViewModel.useAction('copyIds:remove')
  const isHighlightMode = RunbookViewModel.useGet('highlightMode')
  const setHighlightMode = RunbookViewModel.useAction('highlightMode:toggle')
  const copyIds = RunbookViewModel.useGet('copyIds')
  const openModal = RunbookViewModel.useAction('modal:open')

  const menuOptions = useMemo(() => {
    const options: MenuListItemProps[] = [
      {
        label: t('dashboardMenu.exportTasks'),
        icon: 'download',
        onClick: () => {
          openModal({ type: 'tasks-export' })
        },
        appendDivider: true
      },
      {
        label: isHighlightMode ? t('filterMode.switchToDefault') : t('filterMode.switchToHighlight'),
        labelSuffix: t('filterMode.keyboardShortcut'),
        icon: isHighlightMode ? 'filter' : 'highlight-mode',
        onClick: () => setHighlightMode(!isHighlightMode)
      }
    ].filter(Boolean) as MenuListItemProps[]

    if (copyIds.length) {
      options.unshift({
        label: t('clearClipboard'),
        icon: 'dnd-forwardslash',
        onClick: () => removeCopyIds(),
        appendDivider: true
      })
    }

    return options
  }, [copyIds, isHighlightMode])

  return (
    <>
      {menuOptions.length > 0 && (
        <Menu
          trigger={
            <IconButton
              tertiary
              data-testid="sub-header-options-menu"
              label={t('moreOptions')}
              disableTooltip
              icon="more-vertical"
              css="z-index: 1;"
            />
          }
        >
          {menuOptions.map(item => (
            <MenuListItem
              label={item.label}
              labelSuffix={item.labelSuffix}
              suffixColor="text-disabled"
              key={item.label}
              icon={item.icon}
              onClick={item.onClick}
              appendDivider={item.appendDivider}
            />
          ))}
        </Menu>
      )}
    </>
  )
}
